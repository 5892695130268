import React from 'react';
import './styles.css';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { FaFacebook, FaFacebookSquare, FaWhatsappSquare } from "react-icons/fa";
import { AiFillMail } from 'react-icons/ai'

export default function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-item">
          <h2>Quinquilar</h2>
          <p>Aqui você encontra aqueles produtos que raramente encontra em outras lojas. Uma das nossas alegrias é ouvir dos clientes comentários do tipo: "Nossa! esta loja tem de tudo!". "Ahh eu encontrei o que precisava!" "Eu preciso vir aqui com mais tempo" "Eu entro nesta loja, as vezes nem tenho o que comprar mas acabo saindo com alguma coisa.</p>
        </div>
        <div className="footer-item">
          <h2>Contato</h2>
          <address>Rua Cardeal Arcoverde, 2258 - Pinheiros, São Paulo - SP, 05408-002</address>
          <div className="phone-item">
            <AiIcons.AiFillPhone className="phone-footer-icon"/> +55 11 997746000
          </div>
          <div className="phone-item">
            <AiIcons.AiFillMail className="phone-footer-icon"/> atendimento@quinquilar.com.br

          </div>
        </div>
        <div className="footer-item">
          <h2>Acesso Rápido</h2>
          <ul>
          <Link to="/" className="link-header-text"><li><AiIcons.AiFillHome size={20} className="fast-icons"/> Home</li></Link>
            <Link to="/products" className="link-header-text"><li><AiIcons.AiFillInfoCircle size={20} className="fast-icons"/> Produtos</li> </Link> 
            <Link to="/contact" className="link-header-text"><li><AiIcons.AiFillPhone size={20} className="fast-icons"/> Fale conosco!</li></Link>
          </ul>
        </div>
        <div className="footer-item">
          <h2>E-mail</h2>
          <p>Deixe seu email abaixo, entraremos em contato com você.</p>
          <div className="email-footer">
          <a href="https://mail.google.com/mail/?view=cm&fs=1&to=atendimento@quinquilar.com.br" target="_blank"> <AiFillMail size={30}/> </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
      <p>Copyright ©2021 Quinquilar. Todos os direitos reservados.</p>
        <div className="footer-icons">
        <a href="https://www.facebook.com/quinquilar/" target="_blank"><FaFacebookSquare size={30} className="footer-icon"/></a>
        <a href="https://api.whatsapp.com/send?phone=5511997746000&text=Ol%C3%A1%20Quinquilar!" target="_blank"><FaWhatsappSquare size={30} className="footer-icon"/></a>
        </div>
      </div>
    </footer>
  )
}
