import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import './styles.css';
import ImageSlider from '../../components/Slider/ImageSlider';
import { SliderData } from '../../components/Slider/SliderData';
import Product1 from '../../img/product1.jpg'
import Product2 from '../../img/product2.jpg'
import Product3 from '../../img/product3.jpg'
import Product4 from '../../img/product4.jpg'
import Product5 from '../../img/caneca-cafeteira.jpg'
import Product6 from '../../img/panelas-de-ferro.jpg'

export default function Products() {
  return (
    <>
      <Header/>
      <div className="product-container">
        <h1 className="title-products">ITENS QUE VOCÊ PRECISA COMPRAR E ALGUMAS VEZES NEM SABE ONDE!</h1>
        <h2 className="subtitle-products">Destaque para copos de liquidificador, jarras para cafeteiras, panelas de ferro, de alumínio rebatido, de barro, linha hotel & restaurante, ágata, esmaltados, utensílios de cozinha e muito mais.</h2>
          <div className="list-images">
            <img src={Product1} alt="" className="image-item rotate-left"/>
            <img src={Product2} alt="" className="image-item"/>
            <img src={Product3} alt="" className="image-item rotate-right"/>
            <img src={Product4} alt="" className="image-item"/>
            <img src={Product5} alt="" className="image-item"/>
            <img src={Product6} alt="" className="image-item"/>
          </div>
      </div>
      <Footer/>
    </>
  )
}
