import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import * as FiIcons from 'react-icons/fi';
import * as AiIcons from 'react-icons/ai';
import Logo from '../../img/logo.png';

export default function Header() {

    const [blackHeader, setBlackHeader] = useState(false);

  useEffect(()=>{
    const scrollListener = () => {
      if(window.scrollY > 10){
        setBlackHeader(true);
      } else{
        setBlackHeader(false);
      }
    }
    window.addEventListener('scroll', scrollListener);

    return () =>{
      window.removeEventListener('scroll',scrollListener)
    }
  },[]);

    const [Sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!Sidebar);

    return (
        <header className={blackHeader ? 'black' : ''}>
           
           <Link to="/"><img src={Logo} className="logo-header"/></Link>
                <div className="header-links">
                    <ul>
                        <li><Link to="/" className="link-header-text">Home</Link></li>
                        <li><Link to="/products" className="link-header-text">Produtos</Link></li>  
                    </ul>
                    <Link to="/contact" className="link-header-text"><button>Fale conosco!</button></Link>
                </div>
                
                <FiIcons.FiMenu 
                size={30} 
                color={"#fff"} 
                id="menu-icon"
                onClick={showSidebar}
                />

                <nav className={Sidebar ? 'nav-menu active' : 'nav-menu'}>
                <FiIcons.FiX
                size={30} 
                color={"#fff"} 
                id="exit-icon"
                onClick={showSidebar}
                />
                    <ul>
                      <Link to="/" ><li><AiIcons.AiFillHome size={20} className="nav-icons"/> Home</li></Link>
                      <Link to="/products" ><li><AiIcons.AiFillInfoCircle size={20} className="nav-icons"/> Produtos</li></Link> 
                      <Link to="/contact" ><li><AiIcons.AiFillPhone size={20} className="nav-icons"/> Fale conosco !</li></Link>
                    </ul>
                </nav>
         </header>
    )
}