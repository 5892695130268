import React from 'react';
import './styles.css';
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from 'react-router-dom';

export default function Banner(){
  
  return(
    <section className="banner">
      <div className="banner-info">
        <h1>Tudo o que você precisa, em um só lugar!</h1>
        <cite>“Sempre ouvirá a frase "Como posso te ajudar?"... e dentro do possível, vamos fazer você sorrir com uma pitada de humor.”</cite>
        <Link to="/contact"><button>Fale conosco! <BsArrowUpRight style={{marginLeft: '10px'}}/></button></Link>
      </div>
    </section>
  );
};