import React from 'react';
import Banner from '../../components/Banner';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import './styles.css';

import { FaHandHoldingHeart } from "react-icons/fa";
import { BiHappyBeaming, BiBarChartAlt2 } from "react-icons/bi";


export default function Home() {

  return (
    <div className="home-container">
      <Header/>
      <Banner/>
      <div className="home-content">
      <h2>Faça uma visita e experimente uma maneira descontraída de fazer compras.</h2>
        <div className="itens-home">
        
          <div className="item-home">
              <div className="title-item-home">
                <div className="title-home-icon"><BiHappyBeaming size={40} color="#fff"/></div> 
                <h2>Trabalhamos com empatia</h2>
              </div>
            <p>Nossos clientes irão sempre encontrar o bom humor.</p>
          </div>
        
          <div className="item-home">
              <div className="title-item-home">
                <div className="title-home-icon red"><BiBarChartAlt2 size={38} color="#fff"/></div> 
                <h2>variedade & qualidade</h2>
              </div>
            <p>Oferecemos uma variedade de produtos raramente encontrados no mesmo espaço.</p>
          </div>
        
          <div className="item-home">
              <div className="title-item-home">
                <div className="title-home-icon blue"><FaHandHoldingHeart size={30} color="#fff"/></div> 
                <h2>Comprometimento</h2>
              </div>
            <p>Há mais de 20 anos no mesmo local oferecemos credibilidade e confiança.</p>
          </div>
        
        </div>

        <div className="home-about">
          <h1>Como nós podemos te ajudar?</h1>
          <p>Aqui você encontra aqueles produtos que raramente encontra em outras lojas. Uma das nossas alegrias é ouvir dos clientes comentários do tipo:
          "Nossa! esta loja tem de tudo!".
          "Ahh eu encontrei o que precisava!"
          "Eu preciso vir aqui com mais tempo"
          "Eu entro nesta loja, as vezes nem tenho o que comprar mas acabo saindo com alguma coisa.</p>
        </div>
        </div>
      <Footer/>
    </div>
  )
}
