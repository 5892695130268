import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import './styles.css';
import * as AiIcons from 'react-icons/ai';

import Wpp from '../../img/wpp.png';
import Gps from '../../img/gps.png';
import Email from '../../img/email.jpg';


export default function Contact() {
  return (
    <section className="contact-container">
      <Header/>
      <h1>Entre em contato conosco!</h1>
        <div className="contact-info">
          <div className="left-side">
            <div className="left-item">
              <h2>Ligue ou mande uma mensagem!</h2>
              <div className="left-item-icons">
                <a href="https://api.whatsapp.com/send?phone=5511997746000&text=Ol%C3%A1%20Quinquilar!" target="_blank"><div className="icon-left"><img src={Wpp} className="contact-icons"/> +55 11 997746000</div></a>
              </div>
            </div>
            <div className="left-item">
              <h2>Mande um e-mail!</h2>
              <div className="left-item-icons">
              <a href="https://mail.google.com/mail/?view=cm&fs=1&to=atendimento@quinquilar.com.br" target="_blank"><div className="icon-left"><img src={Email} className="email-icons"/> atendimento@quinquilar.com.br</div></a>
              </div>
            </div>
            <div className="left-item">
              <h2>Avalie-nos!</h2>
              <div className="left-item-icons">
                <a href="https://g.page/r/CR78mrxgW0YcEAg/review" target="_blank"><div className="icon-left"><img src={Gps} className="contact-icons"/> Google Maps</div></a>
              </div>
            </div>
        </div>  
          <div className="maps-info">
            <h2>Faça uma visita!</h2>
          <iframe className="maps" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Rua%20Cardeal%20Arcoverde,%202258%20S%C3%A3o%20Paulo+()&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <a href="https://maps-generator.com/pt"></a>
          </div>
        </div>
      <Footer/>
    </section>
  )
}
